<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ title }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="i18nSecurityBanner"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :showCamp="showCamp" tooltip="Current Home"
        :i18n="translations.components"></camp-selector>
      <data-table :fields="dataFields" :items="dtFriends" :hiddenItems="hiddenItems" :campType="campType"
        :csvUrl="friendsReportAsCSVUrl" :pdfUrl="friendsReportAsPDFUrl" :noRecordsMessage="noRecordsMessage"
        :i18n="translations.components" individualType="friend">
      </data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMembersList.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'friends-of-gideons',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      i18nSecurityBanner: { 'security-banner': {} },
      noRecordsMessage: 'No members were found.',
      hiddenItems: {
        display: false,
      },
      secured_member_list_export_controls: {
        export_member_list_button: '61df0f23-0680-4fdf-a4db-9738ce968847',
      },
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      loadCampType: 'user/loadSelectedCampType',
      loadFriends: 'membership/loadFriends',
      loadFriendsReportUrls: 'membership/loadFriendsReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([this.loadCampType(), this.loadFriends(), this.loadFriendsReportUrls()])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.noRecordsMessage = this.translations.tcNoMembersWereFound
    },
  },
  computed: {
    ...mapGetters({
      campType: 'user/userSelectedCampType',
      friends: 'membership/friends',
      friendsReportAsCSVUrl: 'membership/friendsReportAsCSVUrl',
      friendsReportAsPDFUrl: 'membership/friendsReportAsPDFUrl',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      members: 'membership/members',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userLogin: 'user/userLogin'
    }),
    title() {
      return this.translations.tcFriendsOfGideons
    },
    showCamp() {
      return true
    },
    dataFields() {
      return {
        xx: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true },
          { key: 'phone', label: this.translations.tcPhone, sortable: true },
          { key: 'email', label: this.translations.tcPostalCode, sortable: true },
        ],
      }['xx']
    },
    allowPage() {
      if (!!this.$route.path.split('/')[4] && this.$route.path.split('/')[4] !== 'menu') {
        return this.isInMenu(this.$route.path.replace('/' + this.$route.path.split('/')[4], ''))
      } else {
        return this.isInMenu(this.$route.path)
      }
    },
    dtFriends() {
      return this.friends.map((a) => {
        var returnValue = { ...a, ind_key: a.ind_key, name: a.complete_name }
        return returnValue
      })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('data-table-members', 'security-banner', 'camp-select'),
      ]).then((results) => {
        this.loadTranslations()
        const componentTranslations = results[1]
        this.i18nSecurityBanner['security-banner'] = componentTranslations['security-banner']
        this.$set(this.translations, 'components', componentTranslations)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
